@import "../AdminLTE-2.3.0/bootstrap/css/bootstrap.min.css";
@import "../AdminLTE-2.3.0/dist/css/AdminLTE.min.css";
@import "../AdminLTE-2.3.0/dist/css/skins/_all-skins.min.css";

body {
    background: #d2d6de;
}

@media (min-width: 767px) {
    body {
        & > .container {
            //height: calc(100vh - 51px);
        }
    }
    .content-wrapper {
        height: calc(100vh - 101px);
        &.partner-home {
            height: auto;
            min-height: calc(100vh - 101px);
        }
    }
}

.main-footer {
    margin-left: 0;
}


.login-box, .register-box {
    width: 380px;
    @media (max-width: 480px) {
        width: 100%;
        margin-top: 20px;
        .nav-tabs {
            font-size: 12px;
        }
    }
}

.login-page {

    display: flex;
    flex-direction: column;
    min-height: 100vh;

    .login-container {
        flex: 1 0 auto;
        @media (max-width: 350px) {
            padding: 0;
        }
    }
}
#before-load {
    position: fixed; /*фиксированное положение блока*/
    left: 0; /*положение элемента слева*/
    top: 0; /*положение элемента сверху*/
    right: 0; /*положение элемента справа*/
    bottom: 0; /*положение элемента снизу*/
    background: #fff; /*цвет заднего фона блока*/
    z-index: 1001; /*располагаем его над всеми элементами на странице*/
}
#before-load i {
    font-size: 70px; /*размер иконки*/
    position: absolute; /*положение абсолютное, позиционируется относительно его ближайшего предка*/
    left: 50%; /*слева 50% от ширины родительского блока*/
    top: 50%; /*сверху 50% от высоты родительского блока*/
    margin: -35px 0 0 -35px; /*смещение иконки, чтобы она располагалась по центру*/
}
.blocker {
    position: fixed;
    top: 0; right: 0; bottom: 0; left: 0;
    width: 100%; height: 100%;
    overflow: auto;
    z-index: 100;
    padding: 20px;
    box-sizing: border-box;
    background-color: rgb(0,0,0);
    background-color: rgba(0,0,0,0.75);
    text-align: center;
}
.blocker:before{
    content: "";
    display: inline-block;
    height: 100%;
    vertical-align: middle;
    margin-right: -0.05em;
}
.blocker.behind {
    background-color: transparent;
}
.modal {
    display: none;
    vertical-align: middle;
    position: relative;
    z-index: 2;
    max-width: 500px;
    box-sizing: border-box;
    width: 90%;
    background: #fff;
    padding: 15px 30px;
    border-radius: 8px;
    box-shadow: 0 0 10px #000;
    text-align: left;
}

.modal a.close-modal {
    position: absolute;
    top: -12.5px;
    right: -12.5px;
    display: block;
    width: 30px;
    height: 30px;
    text-indent: -9999px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAAA8CAYAAAA6/NlyAAAAAXNSR0IArs4c6QAAA3hJREFUaAXlm8+K00Acx7MiCIJH/yw+gA9g25O49SL4AO3Bp1jw5NvktC+wF88qevK4BU97EmzxUBCEolK/n5gp3W6TTJPfpNPNF37MNsl85/vN/DaTmU6PknC4K+pniqeKJ3k8UnkvDxXJzzy+q/yaxxeVHxW/FNHjgRSeKt4rFoplzaAuHHDBGR2eS9G54reirsmienDCTRt7xwsp+KAoEmt9nLaGitZxrBbPFNaGfPloGw2t4JVamSt8xYW6Dg1oCYo3Yv+rCGViV160oMkcd8SYKnYV1Nb1aEOjCe6L5ZOiLfF120EjWhuBu3YIZt1NQmujnk5F4MgOpURzLfAwOBSTmzp3fpDxuI/pabxpqOoz2r2HLAb0GMbZKlNV5/Hg9XJypguryA7lPF5KMdTZQzHjqxNPhWhzIuAruOl1eNqKEx1tSh5rfbxdw7mOxCq4qS68ZTjKS1YVvilu559vWvFHhh4rZrdyZ69Vmpgdj8fJbDZLJpNJ0uv1cnr/gjrUhQMuI+ANjyuwftQ0bbL6Erp0mM/ny8Fg4M3LtdRxgMtKl3jwmIHVxYXChFy94/Rmpa/pTbNUhstKV+4Rr8lLQ9KlUvJKLyG8yvQ2s9SBy1Jb7jV5a0yapfF6apaZLjLLcWtd4sNrmJUMHyM+1xibTjH82Zh01TNlhsrOhdKTe00uAzZQmN6+KW+sDa/JD2PSVQ873m29yf+1Q9VDzfEYlHi1G5LKBBWZbtEsHbFwb1oYDwr1ZiF/2bnCSg1OBE/pfr9/bWx26UxJL3ONPISOLKUvQza0LZUxSKyjpdTGa/vDEr25rddbMM0Q3O6Lx3rqFvU+x6UrRKQY7tyrZecmD9FODy8uLizTmilwNj0kraNcAJhOp5aGVwsAGD5VmJBrWWbJSgWT9zrzWepQF47RaGSiKfeGx6Szi3gzmX/HHbihwBser4B9UJYpFBNX4R6vTn3VQnez0SymnrHQMsRYGTr1dSk34ljRqS/EMd2pLQ8YBp3a1PLfcqCpo8gtHkZFHKkTX6fs3MY0blKnth66rKCnU0VRGu37ONrQaA4eZDFtWAu2fXj9zjFkxTBOo8F7t926gTp/83Kyzzcy2kZD6xiqxTYnHLRFm3vHiRSwNSjkz3hoIzo8lCKWUlg/YtGs7tObunDAZfpDLbfEI15zsEIY3U/x/gHHc/G1zltnAgAAAABJRU5ErkJggg==');

}
.modal {
    max-width: 700px!important;
    padding: 5px 0!important;
    a.close-modal {

        top: -2.5px!important;
        right: -2.5px!important;
    }
}
.modal-spinner {
    display: none;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);

    padding: 10px!important;
    border-radius: 50%!important;
    background-color: white!important;
    height: 90px!important;
    width: 90px!important;
    i {
        font-size: 70px;
        display: inline-block;
    }
}
